*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'cursive',sans-serif;
}
.jNavText{
  margin-left:50px !important;
}

.jLink a{
  text-decoration: none !important;
}

.logo{
  width: 100px;
}
ul .nav-item {
  margin:0px 30px;
  transition: border 0.7s !important;
}
.jSubmit{
  color: #0072b0 !important;
  border: 1px solid #0072b0 !important;
  margin-left: 0px !important;
  position: relative;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  left:-7px;
}
.jSubmit:hover{
  transition: 0.7s;
  color: #fff !important;
  border:2px solid #0072b0  !important;
  background-color: #0072b0;
}
 .active{
  border-bottom: 1px #ff690b solid ;
}
ul .nav-item:hover {
  margin:0px 30px;
  border-bottom: 1px #ff690b solid ;
}
.text-center{
  text-align: center !important;
}
.jInput{
  background: none !important;
  color: #fff !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
  border: 1px solid #fff !important;
}



.landing{
  margin-top: 50px;
  background: url(./images/background.jpeg) repeat fixed 100%;
  background-size: cover;
  width: 100%;
  height: 50vh;
}
.landingSection{
  height: 100px;
}
.landingTxt{
  color: #fff;
  padding-top:100px;
}
.jWelcomBtn{
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border: 2px solid #ff690b !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  width: 150px;
  color: #fff !important;
}






.Lcontainer{
  width: 250px;
  margin: 0px auto;
  position: relative;
  bottom: 45px;
}
.Lhead{
  background: #fff;
  color: #ff690b;
  font-size: 40px;
  border-radius: 50%;
  height:60px;
  width: 60px;
  margin-left: 89px;
  position: relative;
  top: 5px;
}
.quote{
  color: #ff690b;
  font-size:11px;
}
.jbox{
  padding: 24px  20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}








.section1{
  overflow-x: hidden;
  background-color: rgba(226, 226, 226, 0.966);
}
.profImg{
  width:300px;
  height: 250px;
}
.jboxSection1{
  background-color: #fff;
  padding: 5px 40px 5px 40px !important;
  margin-right: 5px !important;
  border-radius: 5px;

}
.section1Container{
  padding: 10px 40px 0px 40px !important;
  margin: 10px ;
}
.hashTag{
  color: #0072b0;
  font-size: x-large;
  font-weight: 900;
}
.jFontSize{
  font-size: large;
}
.jFontSizeSm{
  font-size: smaller;
}
.jboxSection1 h6{
  color: #0072b0;
}
.section1Container span{
  color: #ff690b;
  font-size: xx-large;
  font-weight: 900;
}
.section1Container div{
  display: inline;
  font-size: x-large;
}
.jBtn1{
  width: 100px !important;
  color: #ff690b !important;
  background-color:#fff !important;
  border: 1px solid #ff690b !important;
}
.jBtn1:hover{
  border: 1px solid #ff690b !important;
  width: 150px !important;
  transition: 0.6s;
}








.jHr{
  text-align: center;
  margin: 55px;
}
.overflow{
  overflow-x: hidden !important;
}
.Sbox{
  height:200px;
  border-right: 2px solid #ff690b;
}
.SboxL{
  height:200px;

}
.SboxL span{
  color: #ff690b;
  font-size: 50px;

}
.Sbox span{
  color: #ff690b;
  font-size: 50px;
}
.num{
  font-size: xx-large;
  font-weight: 900;
}
 .Sp{
  font-size: small;
  color: #0072b0;
}










.AboutContainer{
  margin: 0px auto;
  padding: 50px;
  background-color: rgb(228, 228, 228);
}
.AboutContainer p>span{
  font-size: large;
  font-weight: 900;
}
.AboutList{
  list-style: none;
}
.AboutList span{
  font-size: large;
  color: #0072b0;
  padding: 10px;
}
.Alink{
  float: right;
  color: #ff690b;
  text-decoration: underline;
  cursor: pointer;
}





.social{
  text-align: center;
}
.teamImg{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.teamBox{
  padding: 20px;
}
.teamBoxFlex{
  display: flex;
  flex-direction: row;
}
.teamName{

  margin-left: 40px;
  margin-top:5px;
}
.Tname{
  font-size: large;
  color: #0072b0;
  font-weight: bold;
}
.teamName hr{
  width: 59px;
  padding: 0;
  margin: 5px 0px;
}
.teamName i{
  font-size: 12px;
}
.social span{
  margin: 0px 10px;
  color: #ff690b;
  font-size: large;
}
.teamBox:hover{
  transition: 0.7s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}









.contactContainer b{
  font-size: 12px;
}
.Tname1{
  display: inline-block;
  width: 34px;
  height: 35px;
  color: #fff;
  background-color: #0072b0;
  border-radius: 50%;
  text-align: center;
  padding: 3px;
}
.jflexInput{
  display: flex;
  flex-direction: row;
}
.jM{
  margin-right: 10px;
}
.jIcoContact{
  margin-left: 150px;
}
.jBtn2{
  width: 150px !important;
  text-align: center;
  margin-top: 10px;
}








.footerLogo{
  width: 200px;
  margin: 20px;
}
.footerMargin{

}
.bgColor{
  background-color: #353434;
}
.bgColor p{
  color: #fff;
}
.footerContainer{
  margin-top:0px;
  padding: 100px 0px;
}
.footerLast{
  background-color: #212529;
  height: 60px;
}
.footerLast p{
  padding-top: 15px;
}





.webBg{
  background-color: #f3f3f3;

}

.webContainer{
  width: 750px;
  margin: 0px auto !important;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.JWctn{
  margin: 100px 0px !important;
}
.webContainer i{
  color: #ff690b;
}
.jWebMargin{
  padding-top: 50px;
}
.webNum{
  color: #0072b0;
  font-size: larger !important;
  font-weight: 500;
}





.langActive{
  color: #ff690b !important;
}
.round{
  width: 10px;
  height: 10px;
  background-color: #ff690b;
  border-radius: 50%;
  position: relative;
  top: 5px;
}
.switch{
  margin: 0px 10px;
  cursor: pointer;
}
.langTxt{
  font-size: 13px;
  color: #fff;
  cursor: pointer;
}
.line{
  width: 25px;
  height: 1px;
  background-color: gray;
}










@media only screen and (max-width: 800px) {
  body {

  }
  .landingTxt{

    padding-top:30px !important;
  }
  .webContainer{
    width: 500px;
    text-align: center;
  }
}
